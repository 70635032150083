import { createContext } from "react";

const MedodsContext = createContext({
  states: {
    medodsUsers: [],
    userChecked: {},
    selectedUser: {},
    selectedType: "",
    fullName: {},
    method: "",
    errorMsg: "",
    fieldsError: false,
  },
  actions: {
    setMedodsUsers: () => {},
    setUserChecked: () => {},
    setSelectedUser: () => {},
    setSelectedType: () => {},
    setFullName: () => {},
    setMethod: () => {},
    setErrorMsg: () => {},
    setFieldsError: () => {},
    onHide: () => {},
  },
  constValues: {
    SEARCH_USERS_METHOD: "",
    SEARCH_CLIENTS_METHOD: "",
    FULL_NAME_DEFAULT: {},
  },
});

export default MedodsContext;
