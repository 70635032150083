import { Form } from "react-bootstrap";
import "./styles/CRadioSelect.css";

export const CRadioSelect = ({ options, selectedValue, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <Form>
      {options.map((option, index) => (
        <Form.Check
          key={index}
          inline
          type="radio"
          name="userType"
          id={option.id}
          label={option.label}
          value={option.value}
          defaultChecked={option.value === selectedValue}
          onChange={handleChange}
        />
      ))}
    </Form>
  );
};
