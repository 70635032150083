import { Modal, Form, Container } from "react-bootstrap";
import { CButton } from "../../UI/CButton";
import { usePostRequest } from "../../../hooks/useApiHooks";
import { useContext, useState } from "react";
import NavigationContext from "../../../hooks/NavigationContext";
import UsersContext from "../../../hooks/UsersContext";

export const MedodsRegister = ({ selectedUser, onHide, userType }) => {
  const UNKNOWN_USER_TYPE = "UNKNOWN_USER_TYPE";
  const AUTH_USERNAME_TAKEN = "AUTH_USERNAME_TAKEN";
  const PASSWORD_LENGTH = "PASSWORD_LENGTH";
  const MEDODS_USER_NOT_FOUND = "MEDODS_USER_NOT_FOUND";

  const postRequest = usePostRequest();
  const { openErrorPopup } = useContext(NavigationContext);
  const { actions } = useContext(UsersContext);
  const { fetchUsers } = actions;

  const [username, setUsername] = useState(selectedUser.phone || "");
  const [password, setPassword] = useState("");

  const dropToDefault = () => {
    setUsername("");
    setPassword("");
  };

  const onHideHandler = () => {
    onHide();
    dropToDefault();
  };

  const registerUser = async () => {
    const result = await postRequest("admin/register", {
      username,
      password,
      medods_id: selectedUser?.id,
      medods_user_type: userType,
    });
    if (result.success) {
      dropToDefault();
      fetchUsers();
      onHide();
    } else {
      const errorCode = result.errorCode;
      switch (errorCode) {
        case UNKNOWN_USER_TYPE:
          openErrorPopup(
            `Некорректный тип пользователя. Пожалуйста, обратитесь к разработчикам`
          );
          break;
        case AUTH_USERNAME_TAKEN:
          openErrorPopup(`Пользователь с таким логином уже зарегистрирован`);
          break;
        case PASSWORD_LENGTH:
          openErrorPopup("Длина пароля должна быть не меньше 8 символов");
          break;
        case MEDODS_USER_NOT_FOUND:
          openErrorPopup("Пользователь не найден");
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <Modal.Body>
        <Container>
          <p>
            <strong>Пользователь: </strong>
            {`${selectedUser?.surname || ""} ${selectedUser?.name || ""} ${
              selectedUser?.secondName || ""
            }`}
          </p>
          <Form.Group>
            <Form.Label>Логин</Form.Label>
            <Form.Control
              maxLength={50}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Введите логин пользователя"
            ></Form.Control>
          </Form.Group>
          <div style={{ height: "10px" }} />
          <Form.Group>
            <Form.Label>Пароль</Form.Label>
            <Form.Control
              maxLength={50}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Введите пароль пользователя"
            ></Form.Control>
          </Form.Group>
          <div style={{ height: "10px" }} />
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <CButton negative clickHandler={onHideHandler}>
          Отменить
        </CButton>
        <CButton
          disabled={!username.length || password.length < 8}
          clickHandler={registerUser}
        >
          Сохранить
        </CButton>
      </Modal.Footer>
    </>
  );
};
