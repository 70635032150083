import React, { useState } from "react";
import LoginPage from "./pages/LoginPage";
import AdminPage from "./pages/AdminPage";
import LoadingPage from "./pages/LoadingPage";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/style.css";
import Cookies from "js-cookie";

import NavigationContext from "./hooks/NavigationContext";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import ModalError from "./components/modals/ModalError";

const RoutesWithNavigation = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const navigate = useNavigate();
  const redirectToLogin = () => {
    // Cookies.set("auth_token", undefined, { expires: new Date(0) });
    Cookies.remove("auth_token", { sameSite: "strict" });
    navigate("/login");
  };
  const openErrorPopup = (
    error = "Ошибка при подключении к серверу",
    title = "Ошибка"
  ) => {
    setErrorTitle(title);
    setErrorMsg(error);
  };
  return (
    <NavigationContext.Provider value={{ redirectToLogin, openErrorPopup }}>
      <ModalError
        errorMsg={errorMsg}
        onHide={() => setErrorMsg("")}
        title={errorTitle}
      />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/loading" element={<LoadingPage />} />
        <Route path="*" element={<Navigate to="/loading" />} />
      </Routes>
    </NavigationContext.Provider>
  );
};

function App() {
  return (
    <BrowserRouter>
      <RoutesWithNavigation />
    </BrowserRouter>
  );
}

export default App;
