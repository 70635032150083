import React, { useContext, useState } from "react";
import { usePostRequest } from "../../hooks/useApiHooks";
import { Modal, Form } from "react-bootstrap";
import { CButton } from "../UI/CButton";
import NavigationContext from "../../hooks/NavigationContext";
import UsersContext from "../../hooks/UsersContext";

const ModalChangePassword = function ({ show, onHide }) {
  const [newPassword, setNewPassword] = useState("");
  const { openErrorPopup } = useContext(NavigationContext);
  const { selectedUser } = useContext(UsersContext).state;

  const postRequest = usePostRequest();

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const onHideHandler = () => {
    setNewPassword("");
    onHide();
  };

  async function acceptNewPassword() {
    const result = await postRequest("admin/setPassword", {
      user_id: selectedUser.id,
      password: newPassword,
    });
    if (result.success) {
      if (result.data.status === "SUCCESS") {
        // Пароль успешно изменен
        onHideHandler();
      } else {
        openErrorPopup(
          "Ошибка при изменении пароля для пользователя " +
            selectedUser?.full_name
        );
      }
    }
  }

  return (
    <Modal show={show} onHide={onHideHandler} centered>
      <Modal.Header>
        <Modal.Title>Изменение пароля</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedUser?.full_name && (
          <div>
            <p>
              <strong>Пользователь:</strong> {selectedUser?.full_name}
            </p>
          </div>
        )}
        <Form.Group>
          <Form.Label>Введите новый пароль</Form.Label>
          <Form.Control
            type="password"
            placeholder="Пароль должен содержать не менее 8 символов"
            onChange={handleNewPassword}
            value={newPassword}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <CButton negative clickHandler={onHideHandler}>
          Отменить
        </CButton>
        <CButton
          clickHandler={acceptNewPassword}
          disabled={newPassword.length < 8}
        >
          Сохранить
        </CButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalChangePassword;
