import { Modal } from "react-bootstrap";
import { CButton } from "../UI/CButton";
import { usePostRequest } from "../../hooks/useApiHooks";
import NavigationContext from "../../hooks/NavigationContext";
import UsersContext from "../../hooks/UsersContext";
import { useContext } from "react";

export const ModalRemoveUser = ({ show, onHide }) => {
  const postRequest = usePostRequest();
  const { openErrorPopup } = useContext(NavigationContext);
  const { state, actions } = useContext(UsersContext);
  const { fetchUsers } = actions;
  const { selectedUser } = state;

  const deleteSelectedUser = async () => {
    const result = await postRequest("admin/deleteUser", {
      user_id: selectedUser?.id,
    });
    if (result.success) {
      if (result.data.status === "SUCCESS") {
        fetchUsers();
        onHide();
        return;
      }
      openErrorPopup(
        "Возникла ошибка при удалении пользователя " + selectedUser?.full_name
      );
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header>
        <Modal.Title>Подтверждение</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {`Вы действительно хотите удалить пользователя ${selectedUser?.full_name}?`}
      </Modal.Body>
      <Modal.Footer>
        <CButton negative clickHandler={onHide}>
          Отменить
        </CButton>
        <CButton clickHandler={deleteSelectedUser}>Подтвердить</CButton>
      </Modal.Footer>
    </Modal>
  );
};
