import { useContext, useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import MedodsContext from "../../../hooks/MedodsContext";

export const SearchFields = () => {
  const { states, actions } = useContext(MedodsContext);

  const { fullName } = states;
  const { setFullName, setFieldsError } = actions;

  const [nameError, setNameError] = useState(false);
  const [surnameError, setSurnameError] = useState(false);
  const [secondNameError, setSecondNameError] = useState(false);

  const isValid = (value) => {
    return !value.length || isNaN(value.trim());
  };

  const nameValidator = (name) => {
    setFullName({ ...fullName, name: name });
    setNameError(!isValid(name));
  };

  const surnameValidator = (surname) => {
    setFullName({ ...fullName, surname: surname });
    setSurnameError(!isValid(surname));
  };

  const secondNameValidator = (secondName) => {
    setFullName({ ...fullName, secondName: secondName });
    setSecondNameError(!isValid(secondName));
  };

  useEffect(() => {
    setFieldsError(nameError || surnameError || secondNameError);
    // eslint-disable-next-line
  }, [nameError, surnameError, secondNameError]);

  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>Фамилия</Form.Label>
          <Form.Control
            isInvalid={surnameError}
            maxLength={50}
            value={fullName.surname}
            onChange={(e) => surnameValidator(e.target.value)}
            placeholder="Введите фамилию"
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId="formName">
          <Form.Label>Имя</Form.Label>
          <Form.Control
            maxLength={50}
            value={fullName.name}
            isInvalid={nameError}
            onChange={(e) => nameValidator(e.target.value)}
            placeholder="Введите имя"
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>Отчество</Form.Label>
          <Form.Control
            maxLength={50}
            value={fullName.secondName}
            isInvalid={secondNameError}
            onChange={(e) => secondNameValidator(e.target.value)}
            placeholder="Введите отчество"
          />
        </Form.Group>
      </Col>
    </Row>
  );
};
