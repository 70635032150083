import { Row, Col } from "react-bootstrap";
import { CButton } from "./UI/CButton";
import { CSearchBar } from "./UI/CSearchBar";
import { CPagination } from "./UI/CPagination";
import React, { useContext, useState } from "react";
import ModalPasswd from "./modals/ModalPasswd";
import "./styles/ManagementTools.css";
import { ModalRemoveUser } from "./modals/ModalRemoveUser";
import UsersContext from "../hooks/UsersContext";
import { ModalRegister } from "./modals/ModalRegister";

export const ManagementTools = () => {
  const [showRegister, setShowRegister] = useState(false);
  const [showPasswd, setShowPasswd] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const { state, actions } = useContext(UsersContext);

  const { fetchUsers } = actions;
  const { pagination, selectedUser } = state;
  return (
    <>
      {/* "150px" */}
      <div className="invisible-block" style={{ height: "10px" }} />
      <Row>
        <Col xs={"auto"}>
          <CButton clickHandler={() => setShowRegister(!showRegister)}>
            Создать
          </CButton>
        </Col>
        <Col xs={"auto"} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <CButton
            disabled={!selectedUser}
            clickHandler={() => {
              setShowPasswd(!showPasswd);
            }}
          >
            Изменить пароль
          </CButton>
        </Col>
        <Col style={{ paddingRight: "0px" }}>
          <CButton
            negative={true}
            disabled={!selectedUser}
            clickHandler={() => setShowRemove(!showRemove)}
          >
            Удалить
          </CButton>
          <Row className="d-block d-md-none">
            <div style={{ height: "10px" }}></div>
          </Row>
        </Col>
        <Col xs={"auto"} className="col-pagination">
          <CPagination
            pagination={pagination}
            onPageChanged={(newPageNumber) => fetchUsers(newPageNumber)}
          />
        </Col>
        <Col md={5} lg={4} xl={3} className="ml-auto">
          <CSearchBar />
        </Col>
        <ModalPasswd
          show={showPasswd}
          onHide={() => setShowPasswd(!showPasswd)}
        />
        <ModalRemoveUser
          show={showRemove}
          onHide={() => setShowRemove(!showRemove)}
        />
        <ModalRegister
          show={showRegister}
          onHide={() => setShowRegister(!showRegister)}
        />
      </Row>
    </>
  );
};
