import { useContext } from "react";
import NavigationContext from "../hooks/NavigationContext";
import { CButton } from "./UI/CButton";

import "./styles/Navibar.css";

const Navibar = function () {
  const { redirectToLogin } = useContext(NavigationContext);

  return (
    <>
      <div className="navbar-container " style={{ display: "block" }}>
        <div className="navbar-content">
          <h4 style={{ marginTop: "3px" }}>Панель администратора</h4>
          <CButton clickHandler={redirectToLogin} negative={true}>
            Выход
          </CButton>
        </div>
      </div>
    </>
  );
};

export default Navibar;
