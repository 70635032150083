import { Pagination } from "react-bootstrap";
import classNames from "classnames";
import "./styles/CPagination.css";

export const CPagination = ({ pagination, onPageChanged }) => {
  const { pageNumber, nextDisabled } = pagination;

  const handlePageChange = async (e, newPageNumber) => {
    e.currentTarget.blur();

    onPageChanged(newPageNumber);
  };

  return (
    <Pagination size="sm" className="disable-margin">
      <Pagination.Prev
        onClick={(e) => handlePageChange(e, pageNumber - 1)}
        disabled={pageNumber === 1}
      />
      <Pagination.Item className="cpagination-item">
        <font
          dir="rtl"
          className={classNames({
            "small-font": pageNumber.toString().length > 2,
          })}
        >
          {pageNumber}
        </font>
      </Pagination.Item>

      <Pagination.Next
        onClick={(e) => handlePageChange(e, pageNumber + 1)}
        disabled={nextDisabled}
      />
    </Pagination>
  );
};
