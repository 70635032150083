import axios from "axios";
import Cookies from "js-cookie";
import NavigationContext from "./NavigationContext";
import { useContext } from "react";

const URI = "https://epilab.signumcodis.ru/api/";
const UNKNOWN_ERROR = "UNKNOWN_ERROR";
const INVALID_TOKEN = 401;

const useErrorCodeHandler = () => {
  const { redirectToLogin, openErrorPopup } = useContext(NavigationContext);

  return (e) => {
    const status = e.response?.status;
    if (status === INVALID_TOKEN) {
      redirectToLogin();
      openErrorPopup(
        "Сессия истекла. Пожалуйста, пройдите авторизацию ещё раз",
        "Предупреждение"
      );
      return UNKNOWN_ERROR;
    }
    const errorCode = e.response?.data?.errorCode;
    if (errorCode && errorCode !== "MEDODS_INTERNAL_ERROR") {
      return errorCode;
    } else {
      openErrorPopup();
      return UNKNOWN_ERROR;
    }
  };
};

export const useGetRequest = () => {
  const handleErrorCode = useErrorCodeHandler();

  return async (method, params = {}) => {
    const token = Cookies.get("auth_token");
    const headers = {
      accept: "application/json",
    };
    if (token) {
      headers.Authorization = "Bearer " + token;
    }
    try {
      const response = await axios.get(URI + method, {
        headers: headers,
        params: params,
      });
      return { success: true, data: response.data };
    } catch (e) {
      return { success: false, errorCode: handleErrorCode(e) };
    }
  };
};

export const usePostRequest = () => {
  const handleErrorCode = useErrorCodeHandler();

  return async (method, postData, exceptToken = false) => {
    const token = Cookies.get("auth_token");
    const headers = {
      "Content-Type": "application/json",
      accept: "*/*",
    };
    if (!exceptToken && token) {
      headers.Authorization = "Bearer " + Cookies.get("auth_token");
    }
    try {
      const response = await axios.post(URI + method, postData, {
        headers: headers,
      });
      return { success: true, data: response.data };
    } catch (e) {
      return { success: false, errorCode: handleErrorCode(e) };
    }
  };
};
