import { Modal, Row, Col } from "react-bootstrap";
import { CButton } from "../../UI/CButton";
import { useState, useContext, useEffect, useRef } from "react";
import { CRadioSelect } from "../../UI/CRadioSelect";
import { useGetRequest } from "../../../hooks/useApiHooks";
import { MedodsUsersTable } from "./MedodsUsersTable";
import { CPagination } from "../../UI/CPagination";
import { ExclamationCircle } from "react-bootstrap-icons";
import MedodsContext from "../../../hooks/MedodsContext";
import { SearchFields } from "./SearchFields";

export const MedodsSearch = () => {
  const USERS_NOT_FOUND = "Пользователи не найдены";
  const USER_ALREADY_REGISTRED = "Пользователь уже зарегистрирован";
  const SEARCH_FIELDS_INCORRECT =
    "Поля не могут содержать только числовые значения";

  const { states, actions, constValues } = useContext(MedodsContext);

  const {
    selectedUser,
    selectedType,
    fullName,
    method,
    errorMsg,
    medodsUsers,
    fieldsError,
  } = states;
  const { SEARCH_USERS_METHOD, SEARCH_CLIENTS_METHOD, FULL_NAME_DEFAULT } =
    constValues;
  const {
    setUserChecked,
    setSelectedUser,
    setSelectedType,
    onHide,
    setMethod,
    setErrorMsg,
    setMedodsUsers,
  } = actions;

  const getRequest = useGetRequest();

  const [pageNumber, setPageNumber] = useState(1);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [fetchEnabled, setFetchEnabled] = useState(true);

  const options = [
    { id: "doctor", label: "Врач", value: SEARCH_USERS_METHOD },
    { id: "patient", label: "Пациент", value: SEARCH_CLIENTS_METHOD },
  ];

  const cachedDataRef = useRef({
    fullName: FULL_NAME_DEFAULT,
    method: method,
  });

  const cachedDataHandler = () => {
    cachedDataRef.current = {
      fullName,
      method,
    };
  };

  const isEqualQuery = () => {
    let isEqual = cachedDataRef.current.fullName.name === fullName.name;
    isEqual =
      isEqual && cachedDataRef.current.fullName.surname === fullName.surname;
    isEqual =
      isEqual &&
      cachedDataRef.current.fullName.secondName === fullName.secondName;
    isEqual = isEqual && cachedDataRef.current.method === method;
    return isEqual;
  };

  const findHandler = () => {
    const isEqual = isEqualQuery();
    cachedDataHandler();
    setSelectedType(method === SEARCH_USERS_METHOD ? "USER" : "CLIENT");
    fetchMedodsUsers(isEqual ? undefined : 1);
  };

  const disableFetch = () => {
    setFetchEnabled(false);
    setTimeout(() => {
      setFetchEnabled(true);
    }, 1200);
  };

  const checkRegistered = async () => {
    const result = await getRequest(
      "admin/isRegistered/" + selectedType + "/" + selectedUser.id
    );
    if (result.success) {
      if (result.data) {
        setErrorMsg(USER_ALREADY_REGISTRED);
        return;
      }
      setUserChecked(true);
    }
  };

  const fetchMedodsUsers = async (page = pageNumber, limit = 6) => {
    setErrorMsg("");

    if (!fetchEnabled) return;
    disableFetch();

    let { name, surname, secondName } = cachedDataRef.current.fullName;
    const offset = limit * (page - 1);

    const ltr = page > pageNumber;

    name = String(name).trim();
    surname = String(surname).trim();
    secondName = String(secondName).trim();

    name = name === "" ? null : name;
    surname = surname === "" ? null : surname;
    secondName = secondName === "" ? null : secondName;

    const result = await getRequest("admin/" + cachedDataRef.current.method, {
      name,
      surname,
      secondName,
      limit,
      offset,
    });
    if (result.success) {
      const fetchedUsers = result.data;
      if (fetchedUsers.length) {
        setNextDisabled(fetchedUsers.length < 6);
      } else if (ltr) {
        setNextDisabled(true);
        return;
      } else {
        setErrorMsg(USERS_NOT_FOUND);
      }
      if (pageNumber !== page) {
        setPageNumber(page);
      }
      setSelectedUser(null);
      setMedodsUsers(fetchedUsers);
    }
  };

  useEffect(() => {
    let errorMsg = SEARCH_FIELDS_INCORRECT;
    if (!fieldsError) {
      errorMsg = "";
    }
    setErrorMsg(errorMsg);
    // eslint-disable-next-line
  }, [fieldsError]);

  return (
    <>
      <Modal.Body>
        <SearchFields />
        <Row>
          <div style={{ height: "20px" }} />
        </Row>
        <Row>
          <Col>
            <CRadioSelect
              options={options}
              selectedValue={SEARCH_USERS_METHOD}
              onChange={setMethod}
            />
          </Col>
        </Row>
        <Row>
          <div style={{ height: "20px" }}></div>
          <Col>
            <CButton
              disabled={
                !(
                  fullName.name.trim().length ||
                  fullName.surname.trim().length ||
                  fullName.secondName.trim().length
                ) || fieldsError
              }
              clickHandler={(e) => findHandler()}
            >
              Найти
            </CButton>
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            {!!medodsUsers.length && (
              <CPagination
                pagination={{ pageNumber, nextDisabled }}
                onPageChanged={(newPageNumber) =>
                  fetchMedodsUsers(newPageNumber)
                }
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {!!medodsUsers.length && (
              <>
                <div style={{ height: "10px" }}></div>
                <MedodsUsersTable
                  medodsUsers={medodsUsers}
                  setSelectedUser={setSelectedUser}
                  selectedUser={selectedUser}
                />
              </>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className="text-danger"
          style={{
            display: "flex",
            alignItems: "start",
            textAlign: "start",
            maxWidth: "50%",
          }}
        >
          {!!errorMsg.length && (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ExclamationCircle size={20} style={{ minWidth: "20px" }} />
                <p
                  style={{
                    marginBottom: 0,
                    marginLeft: "10px",
                    fontSize: "12px",
                    whiteSpace: "normal",
                  }}
                >
                  {errorMsg}
                </p>
              </div>
            </>
          )}
        </div>
        <div style={{ display: "flex" }}>
          <CButton negative clickHandler={onHide}>
            Отменить
          </CButton>
          <CButton
            style={{ marginLeft: "10px" }}
            disabled={!selectedUser}
            clickHandler={checkRegistered}
          >
            Продолжить
          </CButton>
        </div>
      </Modal.Footer>
    </>
  );
};
