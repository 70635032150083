import { Modal } from "react-bootstrap";
import { useState } from "react";
import { MedodsRegister } from "./components/MedodsRegister";
import { MedodsSearch } from "./components/MedodsSearch";
import MedodsContext from "../../hooks/MedodsContext";

export const ModalRegister = ({ show, onHide }) => {
  const SEARCH_USERS_METHOD = "searchMedodsUsers";
  const SEARCH_CLIENTS_METHOD = "searchMedodsClients";

  const FULL_NAME_DEFAULT = { name: "", surname: "", secondName: "" };

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedType, setSelectedType] = useState("USER");
  const [userChecked, setUserChecked] = useState(false);
  const [medodsUsers, setMedodsUsers] = useState([]);
  const [fullName, setFullName] = useState(FULL_NAME_DEFAULT);
  const [method, setMethod] = useState(SEARCH_USERS_METHOD);
  const [errorMsg, setErrorMsg] = useState("");
  const [fieldsError, setFieldsError] = useState(true);

  const onExitedHandler = () => {
    setFullName(FULL_NAME_DEFAULT);
    setSelectedUser(null);
    setErrorMsg("");
    setUserChecked(false);
    setMedodsUsers([]);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onExited={onExitedHandler}
      centered
      size="md"
    >
      <Modal.Header>
        <Modal.Title>Регистрация пользователя</Modal.Title>
      </Modal.Header>
      <MedodsContext.Provider
        value={{
          states: {
            medodsUsers,
            userChecked,
            selectedUser,
            selectedType,
            fullName,
            method,
            errorMsg,
            fieldsError,
          },
          actions: {
            setMedodsUsers,
            setUserChecked,
            setSelectedUser,
            setSelectedType,
            setFullName,
            setMethod,
            setErrorMsg,
            setFieldsError,
            onHide,
          },
          constValues: {
            SEARCH_USERS_METHOD,
            SEARCH_CLIENTS_METHOD,
            FULL_NAME_DEFAULT,
          },
        }}
      >
        {!userChecked ? (
          <MedodsSearch />
        ) : (
          <MedodsRegister
            selectedUser={selectedUser}
            onHide={onHide}
            userType={selectedType}
          />
        )}
      </MedodsContext.Provider>
    </Modal>
  );
};
