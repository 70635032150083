import React, { useContext, useState } from "react";
import { Search, XLg } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import "./styles/CSearchBar.css";
import { CButton } from "./CButton";
import UsersContext from "../../hooks/UsersContext";

export const CSearchBar = () => {
  const [text, setText] = useState("");

  const { state, actions } = useContext(UsersContext);

  const { setFilter } = actions;
  const { isFilteredUsers } = state;

  const searchButtonHandler = () => {
    let trimedText = text.trim();
    setFilter(trimedText.length ? text : null);
  };

  const clearButtonHandler = () => {
    setText("");
    setFilter(null);
  };

  return (
    <>
      <InputGroup className="csearchbar-container">
        <Form.Control
          className="no-glow form-control"
          maxLength={150}
          size="sm"
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              searchButtonHandler();
            }
          }}
          placeholder="Введите текст для поиска..."
        />
        <CButton disabled={!text?.length} clickHandler={searchButtonHandler}>
          <Search />
        </CButton>
        <CButton
          disabled={!text?.length && !isFilteredUsers}
          negative={true}
          clickHandler={clearButtonHandler}
        >
          <XLg />
        </CButton>
      </InputGroup>
    </>
  );
};
