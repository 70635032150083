import React, { useEffect } from "react";
import { Spinner, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const LoadingPage = function () {
  const navigate = useNavigate();
  const tokenFromCookie = Cookies.get("auth_token");
  useEffect(() => {
    if (tokenFromCookie) {
      navigate("/admin");
    } else {
      navigate("/login");
    }
  }, []);
  return (
    <div className="holder">
      <Button variant="success" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Загружаем...
      </Button>
    </div>
  );
};

export default LoadingPage;
