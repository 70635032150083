import { Table } from "react-bootstrap";
import "./styles/UsersTable.css";
import "../hooks/useApiHooks";
import { TableBody } from "./TableBody";

const renderTableHeaders = (titles) => {
  return (
    <thead>
      <tr>
        {titles.map((title) => {
          return <th key={title}>{title}</th>;
        })}
      </tr>
    </thead>
  );
};

export const UsersTable = () => {
  const titles = ["№", "Логин", "id", "ФИО", "Доступен", "Тип"];

  return (
    <Table
      striped
      hover
      size="sm"
      responsive
      bordered
      className="custom-bordered-table"
    >
      {renderTableHeaders(titles)}
      <TableBody />
    </Table>
  );
};
