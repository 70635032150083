import { createContext } from "react";

const UsersContext = createContext({
  state: {
    filter: {},
    users: [],
    selectedUser: {},
    isFilteredUsers: false,
    pagination: {
      pageNumber: 1,
      nextDisabled: false,
    },
  },
  actions: {
    setFilter: () => {},
    setUsers: () => {},
    setSelectedUser: () => {},
    fetchUsers: async () => {},
    pagination: {
      setPageNumber: () => {},
      setNextDisabled: () => {},
    },
  },
});

export default UsersContext;
