import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navibar from "../components/Navibar";
import Cookies from "js-cookie";
import { ControlPanel } from "../components/ControlPanel";
import "./styles/AdminPage.css";

const AdminPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("auth_token");
    if (!token) {
      navigate("/login");
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.pathname.includes("/admin")) {
      document.title = "Панель администратора";
    } else if (location.pathname.includes("/login")) {
      document.title = "Авторизация";
    } else {
      document.title = "Загрузка...";
    }
  }, [location.pathname]);

  return (
    <div className="root-container">
      <Navibar />
      <ControlPanel />
    </div>
  );
};

export default AdminPage;
