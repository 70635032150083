import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { usePostRequest } from "../hooks/useApiHooks";
import ModalError from "../components/modals/ModalError";
import { CButton } from "../components/UI/CButton";
import "./styles/LoginPage.css";

export let id = "";
export let token = "";
export let tokenFromCookie;

const LoginPage = function () {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const postRequest = usePostRequest();

  const isFormValid = login.trim() !== "" && password.trim() !== "";
  const handleLoginChange = (e) => {
    setLogin(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const navigate = useNavigate();
  const location = useLocation();

  function pressEnter(e) {
    if (e.key === "Enter" && isFormValid) {
      e.preventDefault();
      sendPost();
    }
  }

  useEffect(() => {
    if (location.pathname.includes("/admin")) {
      document.title = "Панель администратора";
    } else if (location.pathname.includes("/login")) {
      document.title = "Авторизация";
    } else {
      document.title = "Загрузка...";
    }
  }, [location.pathname]);

  useEffect(() => {
    if ((tokenFromCookie = Cookies.get("auth_token"))) {
      navigate("/admin");
    }
    // eslint-disable-next-line
  }, []);

  async function sendPost() {
    const result = await postRequest("login", {
      username: login,
      password: password,
    });

    if (result.success) {
      id = result.data.user_id;
      token = result.data.token;
      Cookies.set("auth_token", token, { sameSite: "strict" });
      navigate("/loading");
    } else {
      if (result.errorCode === "UNKNOWN_ERROR") {
        return "нет соединения с сервером";
      } else {
        setErrorMsg("Неправильный логин или пароль");
      }
    }
  }

  return (
    <div className="holder">
      <div className="center">
        <h5 className="text" style={{ marginTop: "36%" }}>
          Вход в систему
        </h5>
        <div style={{ height: "10%" }}></div>
        <Container>
          <Row>
            <Col>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  sendPost();
                }}
              >
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    maxLength={50}
                    name="auth_token"
                    placeholder="Введите логин"
                    required
                    value={login}
                    onChange={handleLoginChange}
                    onKeyUp={pressEnter}
                    className="custom-form-control"
                  />
                </Form.Group>

                <div style={{ height: "12px" }}></div>

                <Form.Group controlId="formBasicPassword">
                  <Form.Control
                    type="password"
                    name="auth_pass"
                    maxLength={50}
                    placeholder="Введите пароль"
                    required
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyUp={pressEnter}
                    className="mx-auto custom-form-control"
                  />
                </Form.Group>

                <div style={{ height: "42px" }}></div>

                <CButton
                  className="form_auth_button"
                  clickHandler={() => {
                    sendPost();
                  }}
                  style={{
                    marginLeft: "30%",
                    width: "40%",
                    color: "white",
                  }}
                  disabled={!isFormValid}
                >
                  Войти
                </CButton>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <ModalError
        errorMsg={errorMsg}
        onHide={() => {
          setErrorMsg("");
        }}
      />
    </div>
  );
};

export default LoginPage;
