import { usePostRequest } from "../hooks/useApiHooks";
import { useContext, useEffect, useState } from "react";
import { CCheckBox } from "./UI/CCheckBox";
import classNames from "classnames";
import "./styles/TableBody.css";
import UsersContext from "../hooks/UsersContext";

export const TableBody = () => {
  const { state, actions } = useContext(UsersContext);
  const { users, selectedUser } = state;
  const { setUsers, setSelectedUser } = actions;
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const postRequest = usePostRequest();
  const userTypes = new Map([
    ["USER", "Врач"],
    ["CLIENT", "Пациент"],
  ]);

  const updateUsers = (user, index) => {
    const updatedUsers = [...users];
    updatedUsers[index] = user;
    setUsers(updatedUsers);
  };

  const selectUserHandler = (index) => {
    setSelectedIndex(index);
    setSelectedUser(users[index]);
  };

  const changeEnabled = async (e, index) => {
    e.stopPropagation();

    const user = { ...users[index] };
    const result = await postRequest("admin/setEnabled", {
      user_id: user.id,
      active: !user.enabled,
    });
    if (result.success) {
      if (result.data.status === "SUCCESS") {
        user.enabled = !user.enabled;
        updateUsers(user, index);
      }
    }
  };

  useEffect(() => {
    const index = users.findIndex(
      (user) => user && user?.id === selectedUser?.id
    );
    if (index !== -1) {
      setSelectedIndex(index);
      setSelectedUser(users[index]);
    } else {
      setSelectedIndex(-1);
      setSelectedUser(null);
    }
  }, [users]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <tbody className="custom-tbody">
      {Array.from({ length: 15 }).map((_, index) => {
        const user = users[index];
        const {
          id,
          medods_id,
          username,
          full_name,
          enabled,
          medods_user_type,
        } = user || {};
        return (
          <tr
            key={id || index}
            onClick={(e) => {
              selectUserHandler(index);
            }}
            className={classNames({
              "selected-row": selectedIndex === index,
              "disabled-row": !user,
            })}
          >
            <td>{index + 1}</td>
            <td>{username || ""}</td>
            <td>{medods_id || ""}</td>
            <td>{full_name || ""}</td>
            <td style={{ position: "relative" }}>
              {medods_id && (
                <CCheckBox
                  state={enabled}
                  clickHandler={(e) => {
                    changeEnabled(e, index);
                  }}
                />
              )}
            </td>
            <td>{medods_user_type ? userTypes.get(medods_user_type) : ""}</td>
          </tr>
        );
      })}
    </tbody>
  );
};
