import React, { useState } from "react";
import "./styles/CCheckBox.css";
import { Check } from "react-bootstrap-icons";

export const CCheckBox = ({ state, clickHandler, style = {} }) => {
  return (
    <div className="custom-check-box" style={style} onClick={clickHandler}>
      {state && <Check size={16} />}
    </div>
  );
};
