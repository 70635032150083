import React from "react";
import { Modal } from "react-bootstrap";
import { CButton } from "../UI/CButton";

const ModalError = function ({ errorMsg, onHide, title }) {
  return (
    <Modal show={errorMsg} centered onHide={onHide} size="md">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{errorMsg}</Modal.Body>
      <Modal.Footer>
        <CButton negative clickHandler={onHide}>
          Закрыть
        </CButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalError;
