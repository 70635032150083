import { Table } from "react-bootstrap";
import classNames from "classnames";
import "./styles/MedodsUsersTable.css";

export const MedodsUsersTable = ({
  medodsUsers,
  selectedUser,
  setSelectedUser,
}) => {
  const titles = ["№", "ФИО", "id"];

  return (
    <Table size="sm" bordered responsive hover>
      <thead>
        <tr className="medods-table-titles">
          {titles.map((title) => {
            return <th key={title}>{title}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: 6 }).map((_, index) => {
          const user = medodsUsers[index];
          const fullName =
            (user?.surname || "") +
            " " +
            (user?.name || "") +
            " " +
            (user?.secondName || "");
          return (
            <tr
              className={classNames(
                {
                  "selected-row": selectedUser && selectedUser?.id === user?.id,
                  "disabled-row": !user,
                },
                "medods-table-rows"
              )}
              key={index}
              onClick={(e) => setSelectedUser(user)}
            >
              <td>{index + 1}</td>
              <td>{fullName}</td>
              <td>{user?.id || " "}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
