import React from "react";
import "./styles/CButton.css";
import { Button } from "react-bootstrap";

export const CButton = ({
  children,
  clickHandler,
  negative = false,
  disabled = false,
  size = "sm",
  style = {},
}) => {
  return (
    <Button
      size={size}
      disabled={disabled}
      variant={negative ? "danger" : null}
      className={!negative ? "button-neutral" : ""}
      onClick={clickHandler}
      style={style}
    >
      {children}
    </Button>
  );
};
