import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { UsersTable } from "../components/UsersTable";
import "./styles/ControlPanel.css";
import { ManagementTools } from "./ManagementTools";
import { useGetRequest } from "../hooks/useApiHooks";
import UsersContext from "../hooks/UsersContext";
import Cookies from "js-cookie";

export const ControlPanel = () => {
  const INITIAL_PAGE = 1;

  const getRequest = useGetRequest();

  const [filter, setFilter] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [pageNumber, setPageNumber] = useState(INITIAL_PAGE);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [isFilteredUsers, setIsFilteredUsers] = useState(false);
  let tokenFromCookie;

  useEffect(() => {
    fetchUsers(INITIAL_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const fetchUsers = async (page = pageNumber, limit = 15) => {
    let currentPage = page;
    let fetchedUsers = [];

    const ltr = currentPage > pageNumber;

    setIsFilteredUsers(!!filter);

    if ((tokenFromCookie = Cookies.get("auth_token"))) {
      while (currentPage > 0 && fetchedUsers.length === 0) {
        const offset = limit * (currentPage - 1);
        const result = await getRequest("admin/search", {
          filter,
          limit,
          offset,
        });

        if (result.success) {
          fetchedUsers = result.data;
          if (fetchedUsers.length) {
            setNextDisabled(fetchedUsers.length < limit);
          } else if (ltr) {
            setNextDisabled(true);
            return;
          } else {
            currentPage -= 1;
          }
        } else {
          fetchedUsers = [];
          setNextDisabled(true);
          break;
        }
      }

      while (currentPage > 0 && fetchedUsers.length === 0) {
        const offset = limit * (currentPage - 1);
        const result = await getRequest("admin/search", {
          filter,
          limit,
          offset,
        });

        if (result.success) {
          fetchedUsers = result.data;
          if (fetchedUsers.length) {
            setNextDisabled(fetchedUsers.length < limit);
          } else if (ltr) {
            setNextDisabled(true);
            return;
          } else {
            currentPage -= 1;
          }
        } else {
          fetchedUsers = [];
          setNextDisabled(true);
          break;
        }
      }
      setPageNumber(currentPage ? currentPage : INITIAL_PAGE);
      setUsers(fetchedUsers);
    }
  };

  return (
    <UsersContext.Provider
      value={{
        state: {
          filter,
          users,
          selectedUser,
          isFilteredUsers,
          pagination: {
            pageNumber,
            nextDisabled,
          },
        },
        actions: {
          setFilter,
          setUsers,
          setSelectedUser,
          fetchUsers,
          pagination: {
            setPageNumber,
            setNextDisabled,
          },
        },
      }}
    >
      <Container className="control-container">
        <ManagementTools />
        <Row>
          <div style={{ height: "10px" }} />
        </Row>
        <Row>
          <UsersTable />
        </Row>
      </Container>
    </UsersContext.Provider>
  );
};
